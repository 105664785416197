.wrapper {
  @apply w-full pt-50;

  .header {
    @apply mb-5;
  }

  .content {
    @apply flex items-center justify-between w-11/12 mb-4 mx-auto;
  }
}
.buttonStyle {
    @apply flex justify-center items-center h-content py-16 px-25 rounded-20 filter text-17 leading-20 font-bold focus:outline-none;

    &:focus-visible, &:visited, &:active, &:target {
        @apply outline-none border-none;
    }
}

.primaryAddition {
    @apply text-buttonTextColor bg-gradient-to-l from-accentColorDark to-accentColor;
}

.colorAddition {
    @apply bg-lightGray text-textColor;
}

.textStyle {
    @apply bg-transparent text-textColor p-0 underline font-normal;
}
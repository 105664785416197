@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
  overscroll-behavior-y: none; 
}

body {
  @apply bg-bgColor text-textColor;
}
body.keyboard-open {
  position: fixed;
  width: 100%;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.rc-slider-tooltip {
  @apply -top-35 !important;
}
.rc-slider-tooltip-arrow {
  @apply hidden !important;
}
.rc-slider-tooltip-inner {
  @apply border-none bg-transparent shadow-none cursor-pointer !important;
}
.rc-slider-rail {
  @apply h-px bg-blackSemiDark !important;
}
.rc-slider-track {
  @apply hidden;
}
.rc-slider-handle {
  @apply -mt-10 border-none border-0 w-20 h-20 focus:outline-none shadow-none bg-blackSemiDark !important;
}

.react-switch-selector-wrapper {
  @apply rounded-full !important;
}
.react-switch-selector-wrapper::before {
  @apply bg-gradient-to-r from-accentColorDark to-accentColor rounded-full border-none !important;
}
.react-switch-selector-option-label {
  @apply py-10 !important;
}

.custom-scrollbar {
  @apply scrollbar-thin overflow-auto scrollbar-thumb-gray-600;
}

@media (min-width: 1025px) {
  .custom-desktop-scrollbar {
    @apply scrollbar-thin scrollbar-thumb-gray-600;
  }
}

.toastContainer {
  z-index: 9999;
  margin-top: 20px;
}

.custom-select__control {
  border-color: rgba(255, 255, 255, 0.3) !important;
  box-shadow: none !important;
}

.slick-dots li button::before {
  font-size: 10px;
  color: #c97132;
}

.slick-dots li.slick-active button::before {
  color: #c97132;
  opacity: 1;
}

.slick-prev:before {
  color: #c97132 !important;
}
.slick-next:before {
  color: #c97132 !important;
}


  .fileContainer {
    @apply bg-bgColor shadow-none p-0 items-start m-0 w-auto;

    > p {
      @apply m-0;
    }

    .chooseFileButton {
      @apply flex justify-center items-center h-content py-16 px-25 rounded-20 filter text-17 leading-20 font-bold focus:outline-none text-buttonTextColor bg-gradient-to-l from-accentColorDark to-accentColor;

      &:hover {
        @apply bg-gradient-to-l from-accentColorDark to-accentColor;
      }
    }
  }

  .sketch-picker {
    @apply text-black bg-transparent p-0 mr-0;
  }

  .react-switch-selector-wrapper {
    @apply bg-lightGray !important;
  }

  

  .react-select-container {
    @apply bg-lightGray py-5 rounded-20 min-w-75 w-150;
  }

  .react-select-container-full {
    @apply bg-lightGray py-5 rounded-20 min-w-75;
  }

  .react-select-container-full-border {
    @apply bg-lightGray py-0 rounded-md min-w-75 border border-textColor;
  }

  .react-select__control {
    @apply text-textColor px-20;
    cursor: pointer !important;
  }

  .react-select__single-value {
    @apply text-textColor;
  }

  .react-select__menu {
    @apply bg-lightGray ml-10 py-10 -mt-5 rounded-br-lg rounded-bl-lg shadow border-r border-b border-l border-textColor;
    width: calc(100% - 20px) !important;
  }

  .react-select__option {
    @apply px-10 py-1;

    &:hover {
      @apply cursor-pointer bg-accentColor text-buttonTextColor;
    }
  }

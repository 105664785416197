.questionItem {
  @apply grid justify-between bg-lightGray p-20 rounded-xl gap-30;
  grid-template-columns: 2fr 1fr;

  @screen desktop {
    grid-template-columns: 1fr;
  }

  @screen large {
    grid-template-columns: 2fr 1fr;
  }

  .questionQuestionWrapper {
    @apply flex flex-row items-center gap-15;
    height: fit-content;
  }

  .dotsVerticalIcon {
    @apply w-20 h-20;
  }

  .rightInputFields {
    @apply flex items-start justify-start w-full;

    .questionInfoWrapper {
      @apply flex flex-col gap-5 w-full;

      .questionType {
        @apply bg-bgColor py-5 rounded-md;
      }

      .answerOptionsTitle {
        @apply text-12 opacity-60 mt-10;
      }

      .answerOptionsList {
        @apply flex flex-col gap-5;

        .answerOptionAdd {
          @apply flex gap-10 items-center justify-start;

          &::before {
            @apply border-2 border-bgColor w-20 h-20 rounded-full flex-shrink-0;
            content: '';
          }

          &.radio {
            &::before {
              @apply rounded-full;
            }
          }

          &.checkbox {
            &::before {
              @apply rounded-md;
            }
          }

          .answerOptionInput {
            @apply w-full;
            width: 300px !important;
            max-width: 100% !important;
          }

          .plusIcon {
            @apply opacity-60;
          }
        }
      }
    }

    .deleteQuestionBtn {
      @apply text-12 opacity-60 ml-10;
    }
  }
}

.inbodyContainer {
  max-width: 1300px;

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
    column-gap: 40px;

    @screen desktop {
      @apply pr-0;
      grid-template-columns: 1fr;
      row-gap: 60px;
    }

    @screen large {
      @apply pr-80;
      grid-template-columns: 50% 50%;
    }
  }

  .grid > div {
    @apply font-light relative;

    .date {
      @apply text-25 my-auto;
    }

    .button {
      &.buttonDisable {
        @apply opacity-50;
      }
    }

    .subheadline {
      @apply mb-2 tablet:mb-0 desktop:mb-2 font-bold flex;

      &.additionalSpace {
        @screen tablet {
          @apply mb-20;
        }

        @screen desktop {
          @apply mb-2;
        }
      }
    }

    .checkWrapper {
      @apply flex flex-col large:absolute bottom-0 gap-2;
    }

    .segmentalWrapper {
      @apply mb-40;
    }

    .segmentalWrapper > p {
      @apply mb-0 xl:-mb-15;
    }

    .singleRowGrid {
      @apply mb-6 xl:mb-2;
      display: grid;
      grid-template-columns: 1fr;
      align-items: end;

      @screen tablet {
        @apply mb-2;
        grid-template-columns: 55% 45%;
      }

      @screen desktop {
        grid-template-columns: 1fr;
      }

      @screen xl {
        grid-template-columns: 45% 55%;
      }

      .singleRowGrid_first {
        @apply flex gap-2;

        p:nth-child(2),
        .bold {
          @apply font-bold;
        }
      }

      &.reducedGap {
        @media screen and (max-width: 1420px) {
          display: block;
        }

        @media screen and (min-width: 1025px) and (max-width: 1260px) {
          display: grid;
          grid-template-columns: 1fr;
        }

        @screen desktop {
          grid-template-columns: 25% 75%;
        }
      }
    }
  }

  .singleRow {
    @apply flex justify-between mb-1 flex-wrap;

    p:nth-child(2) {
      @apply font-bold;
    }
  }

  .bubbleSection {
    @apply rounded-3xl bg-lightGray py-20 px-10 md:px-20;
  }
}

.nutritionBorderItem {
  @apply text-center relative px-15 md:px-25;
}

.nutritionBorderItemFirst {
  @apply text-center relative pr-15 md:pr-25;
}

.nutritionBorderItem:after,
.nutritionBorderItemFirst:after {
  @apply bg-accentColor;
  content: '';
  width: 1px;
  position: absolute;
  top: 20%;
  height: 60%;
  right: 0;
}

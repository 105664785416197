.chart {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;

  @screen tablet {
    grid-template-columns: 30% 70%;
  }

  .strokeLabel {
    @apply text-textColor;
    fill: var(--textColor);
  }
}

.chart:last-child {
  align-items: start;
}

.faqWrapper {
    @apply space-y-25 mt-40;
}

.btn {
    @apply w-full;

    .btnInner {
        @apply w-full flex justify-between items-center;
    }
    
    .btnText {
        @apply text-18 font-light text-notActiveButtonTextColor;
    }
    
    .icon {
        @apply text-accentColor;
    }
}

.skeletonBtn {
    @apply w-full animate-pulse h-65 bg-lightGray bg-opacity-40;
}
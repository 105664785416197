.backDrop {
  @apply fixed w-screen h-screen bg-black bg-opacity-50 inset-0 z-40;
}

.drawerContainer {
  @apply transform fixed tablet:inset-y-20 inset-y-0 z-50 right-0 tablet:w-auto w-full  bg-bgColor shadow-main md:rounded-tl-42 md:rounded-bl-42 overflow-hidden ease-in-out transition-all duration-300;
}

.drawerCloseButton {
  @apply absolute h-full w-45 flex items-center justify-end cursor-pointer left-0;
}

.wrapper {
  @apply w-full pt-50;

  .header {
    @apply mb-5;
  }

  .content {
    @apply flex items-center justify-between w-11/12 mb-4 mx-auto;
  }
}

.userWrapper {
  @apply w-full;

  .header {
    @apply mb-5;
  }

  .content {
    @apply flex items-center justify-between w-11/12 mb-4 mx-auto;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.filterIcon {
  @apply text-accentColor cursor-pointer transform rotate-90;
}

.editIcon {
  @apply absolute right-2 top-1/2 cursor-pointer;
  transform: translateY(-50%);
}
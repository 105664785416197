.wrapper {
  @apply mx-auto tablet:w-96 w-full flex flex-col items-center mt-50 px-4;
}

.stripeWrapper {
  @apply w-full py-20 space-y-10;

  .switchWrapper {
    @apply w-full my-4 mx-auto;
  }

  .cardInput {
    @apply p-10 border border-textColor rounded-md bg-lightGray;
  }
}

.strikethrough {
  position: relative;
  color: red;
  font-weight: bold;
  
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 45%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    -webkit-transform: skewY(-10deg);
    -moz-transform: skewY(-10deg);
    transform: skewY(-10deg);
  }
}

.checkDiagram {
  @apply flex gap-12;

  .check {
    @apply flex gap-2 items-center;

    .circle {
      @apply w-4 h-4 rounded-full border-2 border-textColor;

      &.active {
        @apply bg-textColor;
      }
    }
  }
}

.header {
  @apply sticky top-0 bg-bgColor z-50 mb-30 pb-10;

  .buttons {
    @apply flex gap-10 flex-wrap justify-between items-center;
  }
}

.content {
  @apply space-y-30 pb-100;
}

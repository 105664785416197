.wrapper {
  @apply flex-1 flex flex-col justify-center px-20 py-40;

  .logo {
    @apply w-1/2 mx-auto;
  }

  .content {
    @apply mt-75 space-y-30;
  }

  .button {
    @apply w-full;

    .socialLogo {
      @apply h-27 w-27 mr-10;
    }

    .text {
      @apply uppercase whitespace-nowrap;
    }
  }

  .comment {
    @apply flex items-center justify-center text-sm;

    .link {
      @apply ml-20 text-accentColor underline flex-shrink-0;
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.backgroundPopupLayerTop0 {
  @apply left-0 absolute h-full w-full bg-black z-100 bg-opacity-75 top-0 pt-40;

  .overlayPopup {
    @apply w-1/2 mx-auto rounded-3xl bg-lightGray;
    height: calc(100vh - 100px);
  }

  
}

.overlayContent {
  @apply  overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 100px);
}

.enter {
  @apply transform transition-transform duration-300;

}
.enterFrom {
  @apply opacity-0 translate-x-full;
}

.enterTo {
  @apply opacity-100 translate-x-0;
}

.recipeLabel {
  @apply font-light text-sm opacity-50;
}

.mainOverlayWrapper {
  @apply relative;
}

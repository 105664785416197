.checkDiagram {
  @apply flex gap-12;

  .check {
    @apply flex gap-2 items-center;

    .circle {
      @apply w-4 h-4 rounded-full border-2 border-textColor;

      &.active {
        @apply bg-textColor;
      }
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(0);
  -webkit-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
}

.noPlan {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.inputStyle {
  @apply appearance-none block w-full pl-25 pr-60 py-10 rounded-full text-18 placeholder-whiteGray focus:outline-none bg-lightGray bg-opacity-20 text-textColor;
}

[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-image: url(data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+Cgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgY2xhc3M9ImgtNiB3LTYiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSIjZmZmZmZmIj4KICA8cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTYgMThMMTggNk02IDZsMTIgMTIiIC8+Cjwvc3ZnPg==);
  background-size: 15px 15px;
  cursor: pointer;
}

.searchWrapper {
  @apply absolute inset-y-0 right-25 flex items-center;

  .searchIcon {
    @apply text-accentColor h-27;
  }
}

.contentItem {
  @apply w-full relative rounded-20 bg-lightGray bg-opacity-10 flex cursor-pointer;

  .imageWrapper {
    @apply h-100 w-150  rounded-20 bg-whiteGray overflow-hidden my-auto;

    &.skeletonImage {
      @apply w-75 h-55;
    }

    .image {
      @apply w-full h-full object-cover;
    }
  }

  .description {
    @apply w-full ml-14 mr-20 relative;

    .category {
      @apply text-10 flex space-x-15 items-center absolute bottom-5;

      .item {
        @apply w-20 text-center;
      }

      .itemSecondary {
        @apply hidden md:block w-20 text-center;
      }
    }
  }

  .skeletonTitle {
    @apply tablet:w-200 w-70 h-20;
  }

  .skeletonValue {
    @apply mx-auto w-20 h-15 rounded;
  }
}

.itemTitle {
  @apply text-14 pt-10 overflow-hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.starRatingWrapper {
  @apply flex gap-2;

  &.readonly {
    @apply pointer-events-none;
  }

  .star {
    @apply w-30 h-30 text-bgColor;

    &.checked {
      @apply text-accentColor;
    }
  }
}

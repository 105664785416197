.header {
  @apply sticky top-0 bg-bgColor z-50 my-30 pb-10;

  .buttons {
    @apply flex gap-10 flex-wrap justify-between items-center;
  }
}

.content {
  @apply space-y-30 pb-100;
}

.statusPayed {
  @apply rounded-md bg-accentColor p-5 font-extralight text-14 flex space-x-5 items-center my-auto;
  height: fit-content;
}

.statusPayment {
  @apply rounded-md bg-secondaryBgColor p-5 font-extralight text-14 flex space-x-5 items-center my-auto;
  height: fit-content;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.contentFitItem {
  @apply flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl border border-textColor;
  width: fit-content;
}
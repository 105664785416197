.wrapper {
  @apply rounded-2xl overflow-x-auto border border-lightDarkGray;

  .table {
    @apply min-w-full;

    .header {
      @apply bg-lightGray text-textColor;

      .cell {
        @apply desktop:px-6 px-3 py-3 text-center text-xs font-medium uppercase tracking-wider;
      }
      .cell2 {
        @apply desktop:px-6 px-3 py-3 text-center text-xs font-medium uppercase tracking-wider relative desktop:sticky left-0 bg-lightGray;
      }
    }

    .body {
      @apply bg-bgColor divide-y divide-gray-200;

      .row {
        @apply divide-x divide-gray-200;

        .cell {
          @apply desktop:px-6 px-3 py-3.5 whitespace-nowrap text-base text-textColor text-center;

          .image {
            @apply h-28 w-28 mx-auto;
          }
          .qrcode {
            @apply mx-auto w-max;
          }
        }

        .cell2 {
          @apply desktop:px-6 px-3 py-3.5 whitespace-nowrap text-base text-textColor text-center relative desktop:sticky left-0 bg-bgColor;

          .image {
            @apply h-28 w-28 mx-auto;
          }
          .qrcode {
            @apply mx-auto w-max;
          }
        }
      }
    }

    .skeletonText {
      @apply h-4;
    }
    .skeletonImage {
      @apply h-28 w-28 mx-auto;
    }
  }
}

.wrapperNoOverflow {
  @apply rounded-2xl overflow-auto border border-textColor;

  .table {
    @apply min-w-full;

    .header {
      @apply bg-lightGray text-textColor;

      .cell {
        @apply desktop:px-6 px-3 py-3 text-center text-xs font-medium uppercase tracking-wider;
      }
      .cell2 {
        @apply desktop:px-6 px-3 py-3 text-center text-xs font-medium uppercase tracking-wider relative desktop:sticky left-0 bg-lightGray z-0 desktop:z-40;
      }
    }

    .body {
      @apply bg-bgColor divide-y divide-textColor;

      .row {
        @apply divide-x divide-textColor;

        .cell {
          @apply desktop:px-6 px-3 py-3.5 whitespace-nowrap text-base text-textColor text-center;

          .image {
            @apply h-28 w-28 mx-auto;
          }
          .qrcode {
            @apply mx-auto w-max;
          }
        }

        .cell2 {
          @apply desktop:px-6 px-3 py-3.5 whitespace-nowrap text-base text-textColor text-center relative desktop:sticky left-0 bg-bgColor z-0 desktop:z-40;

          .image {
            @apply h-28 w-28 mx-auto;
          }
          .qrcode {
            @apply mx-auto w-max;
          }
        }
      }
    }

    .skeletonText {
      @apply h-4;
    }
    .skeletonImage {
      @apply h-28 w-28 mx-auto;
    }
  }
}
.header {
  @apply flex w-full md:w-1/2 justify-between items-center pt-50 md:pt-30 pb-20;
}

.wrapper {
  @apply grid desktop:grid-cols-2 gap-x-60 h-full;

  .menuWrapper {
    @apply desktop:block space-y-40;

    .avatar {
      @apply flex items-center;

      .photo {
        @apply w-100 h-100 rounded-30 overflow-hidden mr-20 hidden;
      }
      .name {
        @apply text-22;
      }
    }

    .menuList {
      @apply desktop:inline-block space-y-25 w-full desktop:pb-0 pb-100;
    }
  }

  .content {
    @apply h-full relative pr-10 desktop:overflow-x-hidden;
  }
}

.userSwitch {
  @apply absolute top-60 right-20 text-12 font-bold text-textColor bg-lightGray rounded-3xl z-150 border-2 border-accentColor;
}
.circleImage {
  @apply mx-auto;
  width: 100%;
  max-height: 250px;
  max-width: 250px;
  min-height: 250px;
}

.circleImageMobile {
  @apply mx-auto;
  width: 100%;
  max-height: 150px;
  max-width: 150px;
  min-height: 150px;
}

.percentageLabel {
  @apply absolute right-15 text-14 font-bold;
  top: 2px;
}

.waterIcon {
  @apply absolute bottom-0 left-1/2;
  transform: translate(-50%, 0);
}

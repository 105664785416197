.header {
  @apply sticky top-0 bg-bgColor z-50;
}

.content {
  @apply grid grid-cols-1 xl:grid-cols-2 gap-30 pb-100 pt-30;

  .button {
    @apply cursor-pointer border-transparent border-2 hover:border-accentColor px-20 py-16 h-full flex justify-center items-center text-center rounded-20 filter text-17 leading-20 font-bold bg-lightGray text-textColor bg-opacity-20;

    .icon {
      @apply text-accentColor mx-auto mb-20;
    }
  }
}

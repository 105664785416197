.wrapper {
  @apply flex-1 flex flex-col justify-center px-20 py-40;

  .gym {
    @apply w-full text-center mb-8;

    .name {
      @apply mt-6 text-2xl font-bold text-textColor;
    }

    .qrcode {
      @apply w-32 h-32 mx-auto;
    }
  }

  .form {
    @apply space-y-30 mt-10;

    .comment {
      @apply flex items-center justify-center text-sm pt-25;

      .link {
        @apply ml-20 text-accentColor underline;
      }
    }
  }

  .button {
    @apply w-full;

    .socialLogo {
      @apply h-27 w-27 mr-10;
    }

    .text {
      @apply uppercase whitespace-nowrap;
    }
  }
}

.commentLegal {
  @apply flex items-center justify-center text-sm;

  .link {
    @apply ml-20 text-accentColor underline cursor-pointer;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.link {
  @apply text-accentColor underline cursor-pointer;
}
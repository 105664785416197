.checkDiagram {
  @apply flex gap-12;

  .check {
    @apply flex gap-2 items-center;

    .circle {
      @apply w-4 h-4 rounded-full border-2 border-textColor;

      &.active {
        @apply bg-textColor;
      }
    }
  }
}

.headerIcon * {
  @apply stroke-current text-textColor;
}

.headerIcon *, .headerIcon svg {
  @apply stroke-current text-textColor;
  stroke: currentColor!important;
}

.headerIconEdit *, .headerIconEdit svg {
  @apply stroke-current text-textColor opacity-25;
  stroke: currentColor!important;
}

.avatar {
  width: 120px; /* Feste Größe für den Avatar-Container */
  height: 120px;
  border-radius: 50%; /* Um es rund zu machen */
  overflow: hidden; /* Überschüssige Teile des Bildes abschneiden */
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Bild proportional zuschneiden */
  object-position: center; /* Fokus auf die Mitte des Bildes legen */
}

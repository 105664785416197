.enter {
  @apply transform transition-transform duration-300;

}
.enterFrom {
  @apply opacity-0 translate-x-full;
}

.enterTo {
  @apply opacity-100 translate-x-0;
}

.modal {
  @apply absolute bg-lightGrayDarker p-8 py-80 shadow-lg z-150 top-0 left-0 bottom-0 right-0;

  @screen desktop {
    @apply top-50 left-1/2 bottom-auto py-8 right-auto transform -translate-x-1/2 -translate-y-1/2 rounded-2xl;
    transform: translateX(-50%);
  }
}

.overlay {
  @apply fixed top-0 left-0 w-full h-full bg-black opacity-50 z-100;
}

.parent {
  @apply rounded-md h-full overflow-hidden;

  &.circle {
    @apply rounded-full;
  }

  .pulse {
    @apply inline-block h-full w-full;
    background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }

    &.translucent {
      background: linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%);
      background-size: 400% 400%;
    }
  }
}
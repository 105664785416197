.wrapper {
  @apply desktop:pl-400 w-full relative h-screen overflow-hidden;

  .container {
    @apply h-full px-20 desktop:pr-55 desktop:pb-50 pb-100;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.wrapper {
  @apply flex-1 flex flex-col justify-center px-20 py-40 items-center;
}

.image {
  @apply w-1/2 mb-80 flex justify-center;
}

.text {
  @apply text-textColor w-full font-semibold text-center text-42 whitespace-pre mb-80;
}
.listContainer {
  @apply grid gap-y-20;
}

.listHeaderWrapper {
  @apply grid;
  grid-template-columns: 7fr 1fr;
}

.listHeader {
  @apply hidden;

  @screen phoneLandscape {
    @apply grid px-6 gap-x-20;
    grid-template-columns: 3fr 1fr;
  }
}

.listHeader,
.listRow {
  .listItem {
    @apply flex items-center justify-start;
  }
}

.listHeader {
  @apply py-20 sticky bg-bgColor z-20;

  .listItem {
    @apply text-12 opacity-60 flex items-center justify-start gap-5 whitespace-nowrap;
  }
}

.listRowWrapper {
  @apply grid;
  grid-template-columns: 7fr 1fr;

  .listRow {
    @apply bg-secondaryBgColor rounded-l-20 py-25 text-15 grid px-6 gap-x-20;

    @screen phoneLandscape {
      grid-template-columns: 3fr 1fr;
    }

    &.isUnanswered {
      @apply font-bold;
    }
  }
}

.listItem {
  @apply opacity-100;

  &.date {
    @apply hidden;

    @screen phoneLandscape {
      @apply block opacity-60;
    }
  }

  &.isUnanswered {
    &::after {
      @apply w-2 h-2 bg-accentColor rounded-full ml-2 -mt-2;
      content: '';
    }
  }
}

.interactions {
  @apply flex items-center justify-center gap-x-15 bg-secondaryBgColor rounded-r-20 pr-6;

  .icon {
    @apply w-25;
    fill: textColor;
  }
}

.emptyList {
  @apply flex items-center justify-center h-full opacity-60 text-15 font-light;
}

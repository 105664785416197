.backgroundPopupLayer {
  @apply -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-100 bg-opacity-75;
}

.overlayPopup {
  @apply mt-100 mx-20 desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.overlayContent {
  @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 300px);
}

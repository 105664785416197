.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.form {
  @apply space-y-30 mt-10;

  .comment {
    @apply flex items-center justify-center text-sm pt-25;

    .link {
      @apply ml-20 text-accentColor underline;
    }
  }
}
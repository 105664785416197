.wrapper {
  @apply flex items-center justify-between;

  &.notContainer {
    @apply px-25 py-20;
  }

  .label {
    @apply text-20 text-textColor font-light mr-4;
  }

  .alternativeLabel {
    @apply text-16 text-textColor font-light mr-4 w-3/4 md:w-auto;
  }

  .switch {
    @apply relative rounded-full inline-flex items-center justify-center h-25 w-44 cursor-pointer focus:outline-none;
    flex-shrink: 0 !important;

    .switchContainer {
      @apply bg-lightGray absolute h-full w-full mx-auto rounded-full;
    }

    .switcher {
      @apply pointer-events-none absolute left-0 inline-block h-25 w-25 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200;

      &.enable {
        @apply translate-x-20 bg-gradient-to-r from-accentColorDark to-accentColor;
      }

      &.disable {
        @apply translate-x-0 bg-lightGrayAlternative;
      }
    }

    .switcherB2B {
      @apply pointer-events-none absolute left-0 inline-block h-25 w-25 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200;

      &.enable {
        @apply translate-x-20 bg-gradient-to-r from-accentColorDark to-accentColor;
      }

      &.disable {
        @apply translate-x-0 bg-lightGrayAlternative;
      }
    }
  }

  &.isBackground {
    @apply bg-secondaryBgColor rounded-full;

    .switchContainer {
      @apply bg-bgColor absolute h-full w-full mx-auto rounded-full;
    }

    .switcher {
      &.disable {
        @apply translate-x-0 bg-lightGrayAlternative;
      }
    }

    .switcherB2B {
      &.disable {
        @apply translate-x-0 bg-blackSemiDark;
      }
    }
  }
}

.subLabel {
  @apply font-extralight opacity-50 text-14 pt-1 text-notActiveButtonTextColor;
}

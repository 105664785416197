.surveyDetailContainer {
  @apply pt-50 overflow-clip font-light;

  .modalContent {
    @apply flex flex-col gap-30 items-center;
  }

  .modalButtonsWrapper {
    @apply flex gap-20;
  }

  .backBtnWrapper {
    @apply block;
    width: fit-content;

    .backBtn {
      @apply rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor;
    }
  }

  .nameWrapper {
    @apply flex flex-col mt-20;

    .surveyTitle {
      @apply text-35 font-light;
    }

    .status {
      @apply opacity-60 text-12;
    }
  }

  .infoWrapper {
    @apply flex justify-between items-start mt-50 flex-wrap gap-40;

    .leftSide {
      @apply flex gap-20 flex-col;

      .assigneesWrapper {
        @apply flex flex-col gap-5 items-start;

        .assigneesLabel {
          @apply text-14 opacity-60;
        }

        .assigneesSelectWrapper {
          @apply flex gap-5;
        }
        .selectAllBtn {
          @apply rounded-full text-14 font-light whitespace-nowrap h-11;
        }
      }

      .frequencyWrapper {
        @apply flex gap-5 flex-col w-full;

        .frequencyLabel {
          @apply text-14 opacity-60;
        }

        .frequencySelect {
          @apply w-200 rounded-full;
          padding: 3px;
        }
      }
    }

    .planWrapper {
      @apply flex flex-col gap-15 items-center;

      .planSwitchWrapper {
        @apply w-full flex gap-20 items-center;

        .planSwitch {
          @apply w-full;

          div {
            @apply leading-24 text-16;
          }
        }

        .planSwitchLabel {
          @apply text-16 leading-24 font-light;
        }
      }

      .datePicker {
        @apply w-full bg-lightGray rounded-full pl-25 py-10 relative;
      }
    }
  }

  .questionsWrapper {
    @apply mt-50 flex flex-col gap-20;
  }

  .addQuestionBtn {
    @apply flex gap-10 items-center p-20 justify-start opacity-60 font-light;
  }

  .buttonsWrapper {
    @apply flex gap-40 mt-50;

    .saveBtn {
      @apply rounded-full font-light;
    }
  }
}

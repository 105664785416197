.listContainer {
  @apply grid gap-y-20;
}

.listHeaderWrapper {
  @apply grid;
  grid-template-columns: 7fr 1fr;
}

.listHeader {
  @apply grid px-6;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;

  @screen desktop {
    grid-template-columns: 3fr 1fr;
  }

  @screen large {
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  }
}

.listHeader,
.listRow {
  .listItem {
    @apply flex items-center justify-start;

    &:nth-last-child(-n + 2) {
      @apply justify-center;
    }

    &:last-child {
      @apply justify-center;
    }

    &.hide {
      @screen desktop {
        @apply hidden;
      }

      @screen large {
        @apply flex;
      }
    }
  }
}

.listHeader {
  @apply py-20;

  .listItem {
    @apply text-12 opacity-60 flex items-center justify-start gap-5;
  }
}

.listRowWrapper {
  @apply grid;
  grid-template-columns: 7fr 1fr;

  .listRow {
    @apply bg-secondaryBgColor bg-opacity-10 rounded-l-20 py-25 text-15 grid px-6;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;

    @screen desktop {
      grid-template-columns: 3fr 1fr;
    }

    @screen large {
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    }

    .listItem {
      @apply opacity-60;

      &:first-child,
      &:nth-last-child(-n + 2),
      &:last-child {
        @apply opacity-100;
      }
    }
  }

  .interactions {
    @apply flex items-center justify-center gap-x-15 bg-secondaryBgColor rounded-r-20 pr-6;

    .interactionButton {
      &:hover {
        .hoverTooltip {
          @apply block;
        }
      }
    }
  }

  &:hover {
    .listRow,
    .interactions {
      filter: brightness(1.03);
    }
  }
}

.hoverTooltip {
  @apply absolute shadow-main bg-bgColor z-20 text-left rounded-md hidden p-10 text-13;
}

.emptyList {
  @apply flex items-center justify-center h-full opacity-60 text-15 font-light;
}

.overlay {
  @apply absolute top-0 left-0 w-full h-full;

  .tooltip {
    @apply absolute shadow-main bg-bgColor z-20 text-left rounded-md hidden p-20;

    &.show {
      @apply gap-y-20 flex flex-col left-1/2 -translate-x-1/2 top-1/3 -translate-y-1/2;
    }

    .infoText {
      @apply text-13 font-light;
    }

    .buttonWrapper {
      @apply flex justify-around;
    }
  }
}

.wrapper {
  @apply grid grid-cols-1 miniLandscape:grid-cols-2 tablet:grid-cols-2 gap-30;
  .skeletonTitle {
    @apply h-20;
  }

  .skeletonValue {
    @apply w-40 h-15 mb-1 mx-auto;
  }

  .title {
    @apply text-15 font-semibold truncate;
  }
  .description {
    @apply grid grid-cols-4 mt-10 text-12 font-medium leading-3;
  }

  .center {
    @apply text-center;
  }
}

.addIcon {
  @apply absolute top-2/4 left-2/4;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.trackingImage {
  @apply pl-10;
  min-width: 60px;
}

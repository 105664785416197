.backgroundPopupLayer {
  @apply -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-100 bg-opacity-75;
}

.backgroundPopupLayerTopPadding {
  @apply -mt-100 -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-100 bg-opacity-75;
}

.backgroundPopupLayerFullSize {
  @apply  bg-black z-100 bg-opacity-75 top-0 bottom-0 left-0 right-0 fixed;
  
}

.overlayPopup {
  @apply mt-100 mx-20 desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.overlayPopupFullSize {
  @apply mt-150 mx-20 desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/3 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.overlayContent {
  @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 300px);
}

.mainOverlayWrapper {
  @apply px-20;
}

.backgroundPopupLayerFullHeight {
  @apply left-0 desktop:-ml-450 desktop:left-auto absolute h-full w-full bg-black z-100 bg-opacity-75;

  .overlayPopup {
    @apply mt-50 mx-0 desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-none min-h-screen desktop:min-h-0 desktop:rounded-3xl shadow-2xl betweenPhoneTable:mx-auto;
  }

  .overlayContent {
    @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
    max-height: calc(100vh - 100px);;
  }

  .mainOverlayWrapper {
    @apply px-0;
  }
}

.backgroundPopupLayerTop0 {
  @apply left-0 absolute h-full w-full bg-black z-100 bg-opacity-75 top-0;

  .overlayPopup {
    @apply mt-50 mx-0 desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-none min-h-screen desktop:min-h-0 desktop:rounded-3xl shadow-2xl betweenPhoneTable:mx-auto;
  }

  .overlayContent {
    @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
    max-height: calc(100vh - 100px);;
  }

  .mainOverlayWrapper {
    @apply px-0;
  }
}

.wrapper {
  @apply w-full pt-50;

  .header {
    @apply mb-5;
  }

  .content {
    @apply flex items-center justify-between w-11/12 mb-4 mx-auto;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
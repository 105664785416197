.label {
  @apply flex items-center w-full;

  .span {
    @apply absolute opacity-0 -z-10;
  }

  .input {
    @apply border-b-2 border-transparent bg-transparent overflow-visible;
    max-width: calc(100% - 50px);

    &:focus {
      @apply outline-none border-b-2 border-accentColor;
    }

    &::placeholder {
      @apply text-textColor opacity-30;
    }
  }

  .pencilIcon {
    @apply opacity-60 text-textColor;

    &:hover {
      @apply cursor-pointer;
    }
  }
}

.surveysOverviewContainer {
  @apply flex flex-col py-40 h-full font-light;

  .topSection {
    @apply flex flex-col gap-20;

    .headlineContainer {
      @apply flex justify-between items-center mb-20;

      .trainerSelectContainer {
        @apply flex flex-col;

        .trainerSelectLabel {
          @apply block text-12 font-normal text-textColor opacity-50 pl-20;
        }

        .trainerSelect {
          @apply rounded-full text-12 bg-bgColor pt-0;
          width: 200px !important;
        }
      }
    }

    .interactionPanel {
      @apply flex justify-between gap-4 flex-wrap mb-60;

      .createSurveyButton {
        @apply h-12 rounded-full font-light;
      }

      .searchAndArchiveContainer {
        @apply flex gap-4;

        .archiveButton {
          @apply h-12 rounded-full font-light flex items-center gap-1.5;

          .archiveIconClose {
            @apply w-4 h-4 mt-0.5;
          }
        }

        .searchBox {
          input {
            @apply font-light;
          }
        }
      }
    }
  }
}

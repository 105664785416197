.container {
  @apply block;
}

.addIcon {
  @apply absolute top-2/4 left-2/4;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.backgroundPopupLayer {
  @apply -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-40 bg-opacity-75;
}

.editPicturePopup {
  @apply mt-100 mx-20 desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.editPictureIngridientPopupContent {
  @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 300px);
}

.nutritionBorderItem {
  @apply text-center relative px-15 md:px-25;
}

.nutritionBorderItemFirst {
  @apply text-center relative pr-15 md:pr-25;
}

.nutritionBorderItem:after,
.nutritionBorderItemFirst:after {
  @apply bg-accentColor;
  content: '';
  width: 1px;
  position: absolute;
  top: 20%;
  height: 60%;
  right: 0;
}

.makroBorderFirst {
  @apply text-12 relative pr-15;
}

.makroBorder {
  @apply text-12 relative px-15;
}

.makroBorder:after,
.makroBorderFirst:after {
  @apply bg-accentColor;
  content: '';
  width: 1px;
  position: absolute;
  top: 20%;
  height: 60%;
  right: 0;
}

.pieceInput {
  @apply font-light text-xs border-opacity-30;
  min-width: 3.75rem;
}

.actionsMenu {
  left: -120px;
}

.trackingImage {
  @apply pl-10;
  min-width: 60px;
}

.mobileButtonGroup {
  @apply absolute w-full flex bottom-0 z-50 pt-20 pb-30 bg-bgColor large:hidden;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.desktopButtonGroup {
  @apply absolute w-full bottom-0 z-50 pt-20 pb-20 bg-bgColor hidden large:flex rounded-b-3xl;
}

.mobileCircles {
  @apply top-0 bg-bgColor z-10 p-10;
  margin-left: -1.25rem;
  margin-right: 1.25rem;
}

.recipeLabel {
  @apply text-12;
}

.userSwitch {
  @apply absolute top-60 right-20 text-12 font-bold text-textColor bg-lightGray rounded-3xl;
}
.wrapper {
  @apply w-full mt-50;

  .header {
    @apply sticky top-0 bg-bgColor z-70 my-30 pb-10;

    .buttons {
      @apply flex gap-10 flex-wrap justify-between items-center;
    }
  }

  .content {
    @apply flex items-center justify-end w-11/12 mb-4 mx-auto;
  }
}

.inputStyle {
  @apply appearance-none block w-300 px-27 py-10 rounded-full text-18 placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor mb-30;

  &.suffixInput {
      @apply pr-60;
  }
}

.textTruncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

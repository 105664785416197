.container {
  @apply rounded-20 w-full overflow-hidden bg-lightGray bg-opacity-10 relative;
}

.image {
  @apply h-150 w-full rounded-20 overflow-hidden bg-whiteGray flex items-center justify-center;

  & > img {
    @apply w-full h-full object-cover;
  }
}

.overlayImage {
  @apply h-220 w-full rounded-20 overflow-hidden bg-whiteGray flex items-center justify-center;

  & > img {
    @apply w-full h-full object-cover;
  }
}

.overlay {
  @apply absolute bottom-0 w-full h-full bg-black bg-opacity-30 flex items-end;
}

.description {
  @apply px-15 pt-10 pb-20 text-textColor;
}

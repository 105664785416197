.naming {
  @apply flex xl:mb-1 text-13 opacity-50;

  .small {
    @apply text-center;
    width: 20%;
  }

  .big {
    @apply text-center;
    width: 60%;
  }
}

.scale {
  @apply w-full flex justify-between items-end;

  .marker {
    @apply bg-lightGrayAlternative  h-2 block;
    width: 2px;

    &:last-child {
      @apply opacity-0;
    }

    &:first-child {
      @apply opacity-0;
    }

    &.orange {
      @apply bg-accentColor h-3;
    }
  }
}

.barWrapper {
  @apply w-full bg-lightGrayAlternative rounded-full relative;

  .bar {
    @apply bg-accentColorDark h-2 rounded-full;

    &.orange {
      @apply bg-accentColor;
    }
  }

  .barHighlight {
    @apply h-2 rounded-full -mt-2 z-20;
    width: 100%;
    clip-path: xywh(20% 0px 20% 100%);
    -webkit-clip-path: xywh(20% 0px 20% 100%) !important;
    transform: translateZ(0);
    div {
      @apply h-2 bg-accentColor absolute top-0 rounded-full;
    }
  }
}

.answerOption {
  @apply flex gap-10 items-center justify-between;

  &::before {
    @apply border-2 border-bgColor w-20 h-20 rounded-full flex-shrink-0;
    content: '';
  }

  &.checkbox {
    &::before {
      @apply rounded-md;
    }
  }

  .answerOptionInput {
    @apply w-full;
    max-width: 300px !important;
  }

  .deleteAnswerOptionBtn {
    @apply text-12 opacity-60;
  }
}

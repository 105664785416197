.surveyDetailContainer {
  @apply overflow-clip font-light;

  .backBtnWrapper {
    @apply block;
    width: fit-content;

    .backBtn {
      @apply rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor;
    }
  }

  .nameWrapper {
    @apply flex flex-col mt-20;

    .surveyTitle {
      @apply text-35 font-light;
    }
  }

  .smallInfoWrapper {
    @apply flex flex-col mt-20;

    .smallInfoText {
      @apply text-12 opacity-60;
    }
  }

  .questionsWrapper {
    @apply flex flex-col gap-20 mt-40;
  }

  .uploadBtn {
    @apply mt-40;
  }
}

.preloader {
  @apply absolute top-1/2 left-1/2 w-full h-full flex justify-center items-center;
  transform: translate(-50%, -50%);
}

.circleImage {
  @apply mx-auto;
  width: 80%;
  max-height: 400px;
  max-width: 400px;
  min-height: 400px;
}

.percentageLabel {
  @apply absolute right-15 text-14 font-bold;
  top: 2px;
}

.inputStyle {
    @apply appearance-none block w-full px-27 py-10 rounded-full text-18 placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor;

    &.suffixInput {
        @apply pr-60;
    }
}

.inputWrapper {
    @apply relative;

    .suffix {
        @apply absolute right-20 top-1/2 transform -translate-y-2/3 text-18 leading-18 text-textColor;
    }
}

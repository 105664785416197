.wrapper {
  @apply flex items-center;

  .checkbox {
    @apply h-4 w-4 border-lightGray rounded;
  }

  .label {
    @apply ml-2 block text-sm text-textColor opacity-50;
  }
}
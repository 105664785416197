.questionItem {
  @apply bg-lightGray p-20 rounded-xl w-full flex flex-col justify-between gap-20;

  .questionText {
    width: inherit;
  }

  .rightInputFields {
    @apply flex items-start justify-start w-full;
  }
}

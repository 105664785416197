.wrapper {
  @apply grid grid-cols-1 miniLandscape:grid-cols-2 tablet:grid-cols-3 gap-30;
}
.skeletonTitle {
  @apply h-20;
}

.skeletonValue {
  @apply w-40 h-15 mb-1 mx-auto;
}

.title {
  @apply text-15 font-semibold truncate;
}
.description {
  @apply grid grid-cols-4 mt-10 text-12 font-medium leading-3;
}

.center {
  @apply text-center;
}

.enter {
  @apply transform transition-transform duration-300;
}
.enterFrom {
  @apply opacity-0 translate-x-full;
}

.enterTo {
  @apply opacity-100 translate-x-0;
}

.tooltip {
  @apply absolute shadow-main bg-bgColor mt-5 z-20 text-left w-200 rounded-md;
  top: 100%;
  left: 0;
}

.backgroundPopupLayer {
  @apply -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-40 bg-opacity-75;
}

.editIngridientPopup {
  @apply mt-100 w-full desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.editIngridientPopupContent {
  @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 200px);
}

.addRecipePopupContent {
  @apply pr-20 overflow-y-scroll scrollbar-none;
  max-height: calc(100vh - 200px);
  scrollbar-width: none;
}

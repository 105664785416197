.sidebar {
    @apply fixed z-20 bottom-15 inset-x-15 bg-bgColor shadow-main rounded-full;

    @screen desktop {
        @apply w-300 inset-55 rounded-42 overflow-hidden;
        
        .navContainer {
            @apply overflow-auto h-full py-50 space-y-35 relative w-full  flex flex-wrap items-stretch;
        }
    }

    .nav {
        @apply text-whiteGray flex p-25 justify-between;

        @screen desktop {
            @apply mt-7 block space-y-35 px-25 py-0;
        }

        .link {
            @apply text-lg flex items-center relative;

            @screen desktop {
                @apply bg-secondaryBgColor bg-opacity-10 rounded-full px-35 py-15 space-x-15;
            }
            
            .icon * {
                @apply stroke-current text-textColor desktop:text-notActiveButtonTextColor;
            }

            .label {
                @apply hidden text-15 text-textColor desktop:text-notActiveButtonTextColor font-semibold uppercase;

                @screen desktop {
                    @apply block;
                }
            }

            &.selected {
                .icon * {
                    @apply stroke-current text-accentColor;
                }

                @screen desktop {
                    @apply bg-gradient-to-l from-accentColorDark to-accentColor;

                    .label {
                        @apply text-buttonTextColor;
                    }

                    .icon *, svg {
                        @apply stroke-current text-buttonTextColor;
                    }
                }
            }
        }
    }

    .logoutBtn {
        @apply hidden w-full;
        @screen desktop {
            @apply block;
        }
    }

    .adminLinkWrapper {
        @apply hidden;

        @screen desktop {
            @apply block;
        }
    }

    .adminBtn {
        @apply absolute top-55 right-50 text-10 text-accentColor;
    }

    .changeBtn {
        @apply absolute -top-30 right-20 text-12 font-bold text-textColor;
    }
}

.userSwitch {
    @apply absolute -top-5 right-20 text-12 font-bold text-textColor bg-lightGray rounded-3xl z-10;
}
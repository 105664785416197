.wrapper {
  @apply flex-1 flex flex-col justify-center px-20 py-40;

  .content {
    @apply w-full mt-5;
  }

  .buttonContainer {
    @apply w-full grid grid-cols-2 justify-center mt-30 gap-x-10;
  }

}

.memberWrapper {
  @apply flex-1 flex flex-col justify-center px-20 py-40 bg-bgColor;

  .content {
    @apply w-full mt-5;
  }

  .buttonContainer {
    @apply w-full grid grid-cols-2 justify-center mt-30 gap-x-10;
  }

}
.wrapper {
  @apply max-w-4xl mx-auto phoneLandscape:py-24 py-12 px-6;

  .title {
    @apply flex flex-col items-center;

    .header {
      @apply text-5xl font-extrabold text-textColor text-center my-4;
    }

    .description {
      @apply text-xl text-textColor phoneLandscape:text-center;
    }

    .switchWrapper {
      @apply phoneLandscape:w-96 w-full my-4 mx-auto;
    }
  }
  .cardWrapper {
    @apply max-w-full mt-12 space-y-4 tablet:space-y-0 tablet:grid tablet:grid-cols-2 tablet:gap-12;

    .card {
      @apply border border-gray-200 bg-lightGray rounded-lg shadow-sm divide-y divide-gray-200;
    }
  }
}

.strikethrough {
  position: relative;
  color: red;
  font-weight: bold;
  
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 45%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    -webkit-transform: skewY(-10deg);
    -moz-transform: skewY(-10deg);
    transform: skewY(-10deg);
  }
}
.answerOptionsWrapper {
  @apply flex flex-col gap-2;

  .answerOption {
    @apply flex items-center gap-2;

    &.cursorPointer {
      &:hover {
        @apply cursor-pointer;
      }

      .input {
        &:hover {
          @apply cursor-pointer;
        }
      }
    }

    .input {
      @apply border-2 border-bgColor w-20 h-20 rounded-full flex-shrink-0;
      appearance: none;
      -webkit-appearance: none;
      margin: 0;

      &.checked {
        @apply relative border-accentColor;

        &:after {
          @apply absolute w-2 h-2 bg-accentColor rounded-full left-1/2 top-1/2;
          transform: translate(-50%, -50%);
          content: '';
        }
      }

      &.checkbox {
        @apply rounded-md;

        &.checked {
          &:after {
            @apply rounded-sm;
          }
        }
      }
    }
  }
}

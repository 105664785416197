.header {
  @apply sticky top-0 bg-bgColor z-50 my-30 pb-10;

  .buttons {
    @apply flex gap-10 flex-wrap justify-between items-center;
  }
}

.content {
  @apply space-y-30 pb-100 w-2/3;

  .form {
    @apply grid grid-cols-1 gap-5 space-y-20;
  }

}
.surveyDetailContainer {
  @apply pt-50 overflow-clip; // todo: font-light

  .backBtnWrapper {
    @apply block;
    width: fit-content;

    .backBtn {
      @apply rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor;
    }
  }

  .nameWrapper {
    @apply flex flex-col mt-20;

    .surveyTitle {
      @apply text-35 font-light;
    }

    .status {
      @apply opacity-60 text-12;
    }
  }

  .smallInfoWrapper {
    @apply flex flex-col mt-20;

    .smallInfoText {
      @apply text-12 opacity-60;
    }
  }

  .questionsWrapper {
    @apply flex flex-col gap-20 mt-40;
  }

  .assigneeWrapper {
    @apply mt-20 w-full flex gap-60;

    .selectWrapper {
      @apply rounded-full w-full;
    }

    .noResultsContainer {
      @apply flex flex-col items-start justify-start;

      .noResultsLabel {
        @apply block text-sm font-normal text-textColor opacity-50 mb-1;
      }

      .noResults {
        @apply px-20 w-auto py-3 rounded-full;
      }
    }
  }

  .iterationWrapper {
    @apply flex items-center gap-10 mt-40;

    .iterationBtn {
      @apply w-30 h-30;

      &:disabled {
        @apply opacity-50 cursor-not-allowed;
      }
    }
  }
}

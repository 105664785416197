.header {
  @apply flex items-center space-x-15 mb-25;

  .filterIcon {
    @apply text-accentColor transform rotate-90;
  }
}

.mobileMakros {
  @apply md:hidden pt-50 flex w-full gap-4 fixed py-4 top-30 left-0 z-10 bg-bgColor;

  margin-top: -2rem;
}

.wrapper {
  @apply space-y-30 py-30 tablet:w-450 tablet:max-w-md;

  .loading {
    @apply py-100 flex justify-center;
  }

  .selector {
    @apply w-full mb-10;
  }
}

.wrapper {
  @apply h-screen overflow-hidden;

  .content {
    @apply grid desktop:grid-cols-2 grid-cols-1 gap-x-60 px-20;
  }
}
.backBtnBg {
  @apply rounded-2xl pr-6 pl-2 py-0.5 xl:pl-0 xl:pb-50;
  
}

.contentItem {
  @apply w-full rounded-20 mb-20 bg-lightGray bg-opacity-10 flex cursor-pointer;

  .imageWrapper {
    @apply w-75 h-55 rounded-20 bg-whiteGray overflow-hidden my-auto;

    &.skeletonImage {
      @apply w-75 h-55;
    }

    .image {
      @apply w-full h-full object-cover;
    }
  }

  .description {
    @apply flex justify-between w-full items-center ml-14 mr-20;

    .category {
      @apply text-10 flex space-x-15 items-center;

      .item {
        @apply w-20 text-center;
      }

      .itemSecondary {
        @apply hidden xl:block w-20 text-center;
      }
    }
  }

  .skeletonTitle {
    @apply tablet:w-200 w-70 h-20;
  }

  .skeletonValue {
    @apply mx-auto w-20 h-15 rounded;
  }
}

.planImageContainer {
  @apply h-96 xl:hidden;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: auto 24rem;
}

.planContent {
  @apply xl:relative rounded-3xl pb-32 bg-bgColor;
  width: 100%;
}

.mobileButtonGroup {
  @apply absolute w-full flex bottom-0 z-50 pt-20 pb-20 bg-bgColor xl:hidden;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.planWrapper {
  @apply xl:rounded-xl relative xl:overflow-y-auto;
}

.planRightWrapper {
  @apply xl:w-1/2 xl:overflow-auto xl:-mb-50;
  scrollbar-width: none;
}

.mainImage {
  @apply object-cover rounded-3xl w-full xl:w-auto h-300 xl:h-3/4 xl:mt-40 border-transparent border-2 hover:border-accentColor;
  max-height: 70vh;
}

.mainImageWithoutPlan {
  @apply object-cover rounded-3xl w-full xl:w-auto h-350 xl:h-3/4 xl:mt-40 opacity-20;
  max-height: 70vh;
}

.recipeLabel {
  @apply font-light text-sm;
}

.addIngridientPopup {
  @apply overflow-y-hidden mt-100 mx-20 xl:mt-0 xl:mx-0 xl:absolute xl:top-40 xl:left-1/2 xl:w-400 bg-bgColor z-50 rounded-3xl shadow-2xl;
}

.editPicturePopup {
  @apply mt-100 w-full desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.editCookingModePopup {
  @apply h-full xl:h-3/4 w-full xl:w-500 max-w-500 xl:mt-0 xl:mx-0 xl:absolute xl:top-40 xl:left-1/2 bg-bgColor z-100 xl:rounded-3xl xl:shadow-2xl relative;
}

.editCookingModePopupContent {
  @apply pr-20 pt-150 xl:pt-0;
  max-height: calc(100vh - 300px);
}

.editPictureIngridientPopupContent {
  @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 300px);
}

.ingridientPopupContent {
  @apply overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600 pr-20;
  max-height: calc(100vh - 380px);
}

.ingridientPopupContentEdit {
  @apply overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600 pr-20;
  max-height: calc(100vh - 300px);
}

.pageWrapper {
  @apply block xl:hidden;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.pageWrapperDesktop {
  @apply xl:flex xl:space-x-60  xl:h-full;
  background: transparent;
  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
}

.ingridientContainer {
  @apply rounded-3xl mt-14 pt-4 pl-4 pr-4 pb-4 bg-lightGray;
}

.actionBtn {
  @apply rounded-3xl px-4 py-4 w-1/2 bg-lightGray;
}

.backgroundPopupLayer {
  @apply -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-40 bg-opacity-75;
}

.nutritionBorderItem {
  @apply text-center relative px-15 xl:px-25;
}

.nutritionBorderItemFirst {
  @apply text-center relative pr-15 xl:pr-25;
}

.nutritionBorderItem:after,
.nutritionBorderItemFirst:after {
  content: '';
  width: 1px;
  position: absolute;
  top: 20%;
  height: 60%;
  right: 0;
  background-color: #fff;
}

.pieceInput {
  @apply font-light text-xs border-opacity-30;
  min-width: 3.75rem;
}

.customAssigneeSelect {
  @apply w-300;

  .placeholder {
    @apply text-textColor opacity-60;
  }

  .control {
    @apply w-full bg-lightGray rounded-full  text-textColor py-1 px-3 border-none z-10;
  }

  .menu {
    @apply bg-lightGray ml-10 py-10 -mt-5 rounded-br-lg rounded-bl-lg shadow border-r border-b border-l border-textColor;
    width: calc(100% - 20px) !important;
  }

  .option {
    @apply bg-lightGray hover:bg-accentColor;
  }

  .costomOption {
    &.isSelected {
      @apply bg-accentColor text-buttonTextColor;
    }
  }

  .clearIndicator {
    @apply text-textColor py-5 rounded-full border-transparent border-2 text-14 opacity-80;

    &:hover {
      @apply border-accentColor cursor-pointer text-textColor;
    }
  }

  .input {
    @apply text-textColor;
  }
}

.searchMobile {
  @apply tablet:hidden block w-full mb-30;
}

.switchContainer {
  @apply w-full pt-0 tablet:pt-30;
}

.searchContainer {
  @apply flex items-center  w-full justify-between tablet:pb-0 pb-10;

  .searchDesktop {
    @apply tablet:block hidden w-full;
  }
  .filterIcon {
    @apply text-accentColor cursor-pointer transform rotate-90;
  }
}

.container {
  @apply max-w-4xl mx-auto pt-30;

  .contentHeader {
    @apply flex justify-between items-center mb-25;
  }
}

.headerInner {
  @apply flex flex-col w-full;
}

.headlineWrapper {
  @apply flex flex-wrap tablet:justify-center justify-end w-full;
}

.backgroundPopupLayer {
  @apply -ml-20 desktop:-ml-450 absolute h-full w-full bg-black z-40 bg-opacity-75;
}

.editIngridientPopup {
  @apply mt-100 w-full desktop:w-500 max-w-500 desktop:mt-0 desktop:absolute desktop:top-40 desktop:left-1/2 bg-bgColor z-60 rounded-3xl shadow-2xl betweenPhoneTable:mx-auto desktop:mx-0;
}

.editIngridientPopupContent {
  @apply pr-20 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600;
  max-height: calc(100vh - 200px);
}

.addRecipePopupContent {
  @apply pr-20 overflow-y-scroll scrollbar-none;
  max-height: calc(100vh - 200px);
  scrollbar-width: none;
}
